import { useStaticQuery, graphql } from "gatsby";
import { ProjectData } from "../types/ProjectData";

export function useProjectsData(): ProjectData[] {
  const data = useStaticQuery(graphql`
    query {
      allSanityProjects(sort: { fields: completedDate, order: DESC }) {
        edges {
          node {
            id
            projectName
            slug {
              current
            }
            technologies
            featured
            _rawThumbnail
            completedDate(formatString: "MMM YYYY")
            githubLink
            itchLink
            _rawBodyText
          }
        }
      }
    }
  `);

  return data.allSanityProjects.edges.map((x) => x.node as ProjectData);
}
