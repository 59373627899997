import * as React from "react";
import { ProjectData } from "../../data/types/ProjectData";
import { Grid, Paper, Typography, Theme } from "@mui/material";
import { Link } from "gatsby";
import myClient from "../../../sanity-client";
import imageUrlBuilder from "@sanity/image-url";

export interface IPortfolioItemProps {
  item: ProjectData;
}

const builder = imageUrlBuilder(myClient);

export function PortfolioItem(props: IPortfolioItemProps) {
  const thumbnailUrl = builder.image(props.item._rawThumbnail) + "?h=568";

  return (
    <React.Fragment key={props.item.id}>
      <Grid item xs={12} md={6}>
        <Paper
          sx={{
            backgroundImage: `url(${thumbnailUrl})`,
            height: "360px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "10px",
          }}
        >
          <Link to={`/projects/${props.item.slug.current}`}>
            <Paper
              sx={{
                backgroundColor: (theme: Theme) =>
                  theme.palette.background.paper,
                opacity: "0",
                height: "100%",
                transition: "0.3s",
                "&:hover": {
                  opacity: "0.9",
                },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                borderRadius: "10px",
              }}
            >
              <Typography variant="h4">{props.item.projectName}</Typography>
            </Paper>
          </Link>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}
