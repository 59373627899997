import configuration from "./configuration.json";

const sanityClient = require("@sanity/client");

const myClient = sanityClient({
  projectId: configuration.sanity.source.projectId,
  dataset: configuration.sanity.source.dataset,
  apiVersion: "2021-12-11", // use current UTC date - see "specifying API version"!
  useCdn: true, // `false` if you want to ensure fresh data
});

export default myClient;
